'use client'

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import Link from '../Link/Link'
import { useAppSelector } from '@/lib/store'
import { getCurrentUser, getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  const user = useAppSelector(getCurrentUser)
  const userIsLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)

  let priceLink = '/pricing'

  if (user?.user_membership ?? userIsLoggedIn) {
    priceLink = '/plan'
  }

  // Manage open/closed state of each faq item
  const [openStates, setOpenStates] = React.useState<boolean[]>(new Array(6).fill(false))

  // Function to toggle the faq text visibility for a specific index
  const toggleFaq = (index: number): void => {
    const newOpenStates = [...openStates]

    newOpenStates[index] = !newOpenStates[index]
    setOpenStates(newOpenStates)
  }

  return (
    <div className="mx-auto my-20 max-w-4xl px-4 md:px-6 lg:px-8 xl:px-0" id="faq">
      <div className="mb-12 text-center">
        <h2 className="mb-2 text-2xl font-bold md:text-xl lg:text-2xl xl:text-3xl">Frequently Asked Questions</h2>
        <p>
          Can&apos;t find what you&apos;re looking for? Our support team is here to help! Just <Link href="/contact">reach out</Link> at
          anytime.
        </p>
      </div>

      <ul>
        <li>
          <h4 className="relative cursor-pointer py-5 pr-8 font-medium">
            <button
              type="button"
              className="block w-full text-left"
              aria-label="What is an SVG file?"
              onClick={() => {
                toggleFaq(0)
              }}
            >
              What is an SVG file?
              <FontAwesomeIcon
                icon={openStates[0] ? faMinus : faPlus}
                className="text-default absolute right-0 top-1/2 size-4 -translate-y-1/2"
              />
            </button>
          </h4>
          <div className={`faq-text text-baseSM pb-8 ${openStates[0] ? 'block' : 'hidden'}`}>
            <p className="mb-3">
              SVG, short for Scalable Vector Graphics, is a widely used file format for describing two-dimensional vector graphics in XML
              (Extensible Markup Language). Unlike raster image formats like JPEG or PNG, which store images as a grid of pixels, SVG files
              define images using mathematical expressions to represent shapes such as lines, curves, and polygons.
            </p>
            <p className="mb-3">
              One of the key advantages of SVG files is scalability. Since they are based on vectors rather than pixels, SVG graphics can be
              resized without losing quality, making them ideal for logos, icons, and other graphics that need to be displayed at various
              sizes across different devices and screen resolutions.
            </p>
            <p className="mb-3">
              Additionally, SVG files are lightweight and can be easily manipulated using code, making them popular for web development and
              interactive graphics. They can be styled using CSS (Cascading Style Sheets) and animated using JavaScript, providing designers
              with a high degree of flexibility and creativity.
            </p>
            <p>
              In summary, SVG files offer a versatile and efficient way to create and display graphics on the web, providing scalability,
              interactivity, and compatibility across different platforms and devices.
            </p>
          </div>
        </li>
        <li className="border-t">
          <h4 className="relative cursor-pointer py-5 pr-8 font-medium">
            <button
              type="button"
              className="block w-full text-left"
              aria-label="Can I use downloaded SVG images for commercial purposes?"
              onClick={() => {
                toggleFaq(1)
              }}
            >
              Can I use downloaded SVG images for commercial purposes?
              <FontAwesomeIcon
                icon={openStates[1] ? faMinus : faPlus}
                className="text-default absolute right-0 top-1/2 size-4 -translate-y-1/2"
              />
            </button>
          </h4>
          <div className={`faq-text text-baseSM pb-8 ${openStates[1] ? 'block' : 'hidden'}`}>
            <p className="mb-3">
              Yes, the SVG images you generate with us are fully available for commercial use. You have the right to use these SVG images
              for a variety of purposes, including commercial projects.
            </p>
            <p className="mb-3">
              However, if your SVG image includes elements such as fonts, specific graphics, etc., which may be subject to third-party
              copyright or licensing agreements, it is important for you to obtain permissions suitable for commercial use of these
              elements. We take no responsibility for what you generate and what you use for commercial purposes. We strongly recommend that
              you always check the licensing agreements of any external resources integrated into your SVG creations to avoid any legal
              complications.
            </p>
            <p>
              Please read our{' '}
              <Link href="/trademark-disclosure">
                <span className="text-primary hover:text-default">trademark disclosure</span>
              </Link>{' '}
              for additional information and responsibilities.
            </p>
          </div>
        </li>
        <li className="border-t">
          <h4 className="relative cursor-pointer py-5 pr-8 font-medium">
            <button
              type="button"
              className="block w-full text-left"
              aria-label="Do I have an image generation limit?"
              onClick={() => {
                toggleFaq(2)
              }}
            >
              Do I have an image generation limit?
              <FontAwesomeIcon
                icon={openStates[2] ? faMinus : faPlus}
                className="text-default absolute right-0 top-1/2 size-4 -translate-y-1/2"
              />
            </button>
          </h4>
          <div className={`faq-text text-baseSM pb-8 ${openStates[2] ? 'block' : 'hidden'}`}>
            <p className="mb-3">Yes, indeed. We provide a daily limit of 5 image generations, all within a single style.</p>
            <p className="mb-3">
              However, if you are keen to tap into more creativity and access additional image generations, we recommend exploring our{' '}
              <Link href={priceLink}>
                <span className="text-primary hover:text-default">pricing options</span>
              </Link>
              . Whether you are a casual user or a professional in need of extensive creative resources, our pricing plans are designed to
              accommodate your requirements.
            </p>
            <p>
              Please feel free to{' '}
              <Link href="/contact">
                <span className="text-primary hover:text-default">contact us</span>
              </Link>{' '}
              if you have any questions.
            </p>
          </div>
        </li>
        <li className="border-t">
          <h4 className="relative cursor-pointer py-5 pr-8 font-medium">
            <button
              type="button"
              className="block w-full text-left"
              aria-label="How do we handle the payment?"
              onClick={() => {
                toggleFaq(3)
              }}
            >
              How do we handle the payment?
              <FontAwesomeIcon
                icon={openStates[3] ? faMinus : faPlus}
                className="text-default absolute right-0 top-1/2 size-4 -translate-y-1/2"
              />
            </button>
          </h4>
          <div className={`faq-text text-baseSM pb-8 ${openStates[3] ? 'block' : 'hidden'}`}>
            <p className="mb-3">
              We utilize Stripe, a reliable and secure payment gateway, to process transactions smoothly. When you are ready to make a
              purchase, simply proceed to checkout where you will be guided through the secure payment process powered by Stripe.
            </p>
            <p>
              If you have any inquiries regarding our payment procedures or require assistance at any stage, feel free to{' '}
              <Link href="/contact">
                <span className="text-primary hover:text-default">contact us</span>
              </Link>
              . We are here to ensure your payment experience is smooth and worry-free.
            </p>
          </div>
        </li>
        <li className="border-t">
          <h4 className="relative cursor-pointer py-5 pr-8 font-medium">
            <button
              type="button"
              className="block w-full text-left"
              aria-label="What pricing options are available?"
              onClick={() => {
                toggleFaq(4)
              }}
            >
              What pricing options are available?
              <FontAwesomeIcon
                icon={openStates[4] ? faMinus : faPlus}
                className="text-default absolute right-0 top-1/2 size-4 -translate-y-1/2"
              />
            </button>
          </h4>
          <div className={`faq-text text-baseSM pb-8 ${openStates[4] ? 'block' : 'hidden'}`}>
            <p className="mb-3">
              We offer a variety of{' '}
              <Link href={priceLink}>
                <span className="text-primary hover:text-default">pricing options</span>
              </Link>{' '}
              designed to cater to a wide range of needs. Our image generation services come with different limits to accommodate various
              usage levels. Whether you are an individual user, a small business, or a large enterprise, we have flexible options tailored
              to meet your specific needs.
            </p>
            <p>
              If you require additional generation of images, we can provide custom plans tailored to your needs. Simply{' '}
              <Link href="/contact">
                <span className="text-primary hover:text-default">contact us</span>
              </Link>{' '}
              for more information. Additionally, we offer an{' '}
              <Link href={`${process.env['NEXT_PUBLIC_SVGIO_API_URL']}/v1/docs`}>
                <span className="text-primary hover:text-default">API</span>
              </Link>{' '}
              for easy integration into your system.
            </p>
          </div>
        </li>
        <li className="border-t">
          <h4 className="relative cursor-pointer py-5 pr-8 font-medium">
            <button
              type="button"
              className="block w-full text-left"
              aria-label="Can I cancel my subscription at anytime?"
              onClick={() => {
                toggleFaq(5)
              }}
            >
              Can I cancel my subscription at anytime?
              <FontAwesomeIcon
                icon={openStates[5] ? faMinus : faPlus}
                className="text-default absolute right-0 top-1/2 size-4 -translate-y-1/2"
              />
            </button>
          </h4>
          <div className={`faq-text text-baseSM pb-8 ${openStates[5] ? 'block' : 'hidden'}`}>
            <p>
              Absolutely. You have the freedom to cancel your subscription whenever you wish. Upon cancellation, your subscription will
              remain active until the end of your current billing cycle. This means you will continue to have access to our services until
              the billing period you have already paid for expires.
            </p>
          </div>
        </li>
        <li className="border-t">
          <h4 className="relative cursor-pointer py-5 pr-8 font-medium">
            <button
              type="button"
              className="block w-full text-left"
              aria-label="Can I change my subscription at anytime?"
              onClick={() => {
                toggleFaq(6)
              }}
            >
              Can I change my subscription at anytime?
              <FontAwesomeIcon
                icon={openStates[6] ? faMinus : faPlus}
                className="text-default absolute right-0 top-1/2 size-4 -translate-y-1/2"
              />
            </button>
          </h4>
          <div className={`faq-text text-baseSM pb-8 ${openStates[6] ? 'block' : 'hidden'}`}>
            <p>
              Yes, absolutely. Users have the flexibility to modify their subscription plans at any moment. Whether you wish to upgrade to a
              higher tier, downgrade to a lower one, or simply switch to an annual payment plan for added savings, you have the freedom to
              make these adjustments whenever it suits you best.
            </p>
          </div>
        </li>
      </ul>
    </div>
  )
}) satisfies React.FC<Props>
