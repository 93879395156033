import Link from 'next/link'
import * as React from 'react'
import { useAppSelector } from '@/lib/store'
import { getCurrentUser, getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'

export default ((): React.ReactElement => {
  const user = useAppSelector(getCurrentUser)
  const userIsLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)

  let link = '/pricing'

  if (user?.user_membership) {
    link = '/generate'
  } else if (userIsLoggedIn) {
    link = '/plan'
  }

  return (
    <div className="bg-light px-4 py-6 md:px-6 lg:px-8 xl:px-0">
      <div className="container mx-auto grid p-4 text-center md:p-12">
        <div className="text-primary text-2xl font-bold md:text-xl lg:text-2xl xl:text-3xl">
          <p className="mb-4 md:mb-0">Gain exclusive access to premium features by upgrading now.</p>
          <p>Elevate your creativity and unlock a world of possibilities.</p>
        </div>
        <div className="mt-10">
          <Link
            href={link}
            className="bg-primary hover:bg-secondary rounded px-6 py-3 text-white transition-all duration-300 ease-in-out"
            title="Get your access"
          >
            Get your access
          </Link>
        </div>
      </div>
    </div>
  )
}) satisfies React.FC
