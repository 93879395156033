import Link from 'next/link'
import * as React from 'react'

interface Props {
  href: string
  children: React.ReactNode
}

export default (({ href, children }): React.ReactElement => {
  return (
    <Link href={href} className="text-primary hover:text-default transition-all duration-300 ease-in-out">
      {children}
    </Link>
  )
}) satisfies React.FC<Props>
